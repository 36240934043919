export default {
	layout: {
		'/': 'Login',
		'/login': 'Login',
		'/reports': 'Reports',
		'/buildings': 'Buildings',
		'/sensors': 'Sensors',
		'/sensor-keys': 'AES Keys',
		'/gateways': 'Gateways',
		'/ftp-configs': 'FTP Configurations',
		'/integration-configs': 'Integrations',
		'/operators': 'Operators',
		'/customers': 'Customers',
		'/users': 'Users',
		'/settings': 'Settings',
		'/organizations': 'Organizations',
		'/uvis': 'UVIs',
		'/billing': 'Billing',
		'/dashboard': 'Dashboard',
		'/telegrams': 'Telegrams',
		'/devices': 'Devices',
		'/shared-transfer-configs': 'Shared Transfer Configurations',
		Menu: 'Menu',
		Settings: 'Settings',
		Logout: 'Logout',
		Administration: 'Administration',
	},
	table: {
		'No records to display': 'No records to display',
		Add: 'Add',
		Delete: 'Delete',
		Edit: 'Edit',
		Filter: 'Filter',
		'Are you sure delete this row?': 'Are you sure delete this row?',
		Cancel: 'Cancel',
		Save: 'Save',
		placeholder: 'placeholder',
		Actions: 'Actions',
		'{from}-{to} of {count}': '{from}-{to} of {count}',
		rows: 'rows',
		'Rows per page': 'Rows per page:',
		'First Page': 'First Page',
		'Previous Page': 'Previous Page',
		'Next Page': 'Next Page',
		'Last Page': 'Last Page',
		'Add or remove columns': 'Add or remove columns',
		'{0} row(s) selected': '{0} row(s) selected',
		'Show Columns': 'Show Columns',
		Export: 'Export',
		'Export as CSV': 'Export as CSV',
		Search: 'Search',
		'Reset table settings': 'Reset table settings',
	},
	errors: {
		'Invalid e-mail': 'Invalid e-mail',
		'Invalid password': 'Invalid password',
	},
	login: {
		'E-mail Address': 'E-mail Address',
		Password: 'Password',
		"E-mail can't be blank": "E-mail can't be blank",
		"Password can't be blank": "Password can't be blank",
		'Password must have more than 6 characters':
			'Password must have more than 6 characters',
		'Log in': 'Log in',
		Admin: 'Admin',
	},
	reportErrors: {
		'Duplicate serial number': 'Duplicate serial number',
		'Identical sensors': 'Identical sensors',
		'Swapped sensors': 'Swapped sensors',
		'External number and serial number are mixed up': 'External number and serial number are mixed up',
	},
	report: {
		'Main reports': 'Main reports',
		New: 'New',
		Open: 'Open',
		'In Progress': 'In Progress',
		Submitted: 'Submitted',
		Confirmed: 'Imported',
		'Marked Wrong': 'Recycle bin',
		ID: 'ID',
		ID1: 'ID1',
		ID4: 'ID4',
		Status: 'Status',
		'Creation Date': 'Creation Date',
		'Update Date': 'Update Date',
		'Reading Date': 'Reading Date',
		'Submission Date': 'Submission Date',
		'Set Date': 'Set Date',
		'Building Address': 'Building Address',
		'Alternate Address': 'Alternate Address',
		'ZIP code': 'ZIP code',
		City: 'City',
		'Sensors Count': 'Sensors Count',
		'Meeters Count': 'Meeters Count',
		'Serials Count': 'Serials Count',
		'New Report': 'New Report',
		'Report not found': 'Report not found',
		'Report {{id}}': 'Report {{id}}',
		Operator: 'Operator',
		'Operator comment': 'Operator comment',
		'Last Seen': 'Last Seen',
		Comment: 'Comment',
		Sensors: 'Sensors',
		Serials: 'Serials',
		Statistics: 'Statistics',
		'Set date radio measurements': 'Set date radio measurements',
		'Reading date radio measurements': 'Reading date radio measurements',
		'Reading date hand measurements': 'Reading date hand measurements',
		'Sensors with telegram count': 'Sensors with telegram',
		'Radio Collected Count': 'Measurements from radio',
		'Hand Collected Count': 'Measurements by hand',
		'{{colledCount}} of {{sensorsCount}}':
			'{{colledCount}} of {{sensorsCount}}',
		'{{radioCollectedCount}} of {{serialsCount}}':
			'{{radioCollectedCount}} of {{serialsCount}}',
		Download: 'Download',
		Edit: 'Edit',
		Confirm: 'Status imported',
		'Building sensors': 'Building sensors',
		'Apartment sensors': 'Apartment sensors',
		'Go to Building': 'Go to Building',
		'Open map': 'Open map',
		Refresh: 'Refresh',
		'Refresh related Telegrams': 'Refresh related Telegrams',
		'This operation will schedule re-processing all measurements of telegrams from this report. Are you sure?':
			'This operation will schedule re-processing all measurements of telegrams from this report. Are you sure?',
		'Add sensors to report': 'Add sensors to report',
		'All sensors': 'All sensors',
		Readings: 'Readings',
		'Serial Number': 'Serial Number',
		'Radio Measurement Value': 'Radio Measurement Value',
		'Radio Measurement Date': 'Radio Measurement Date',
		'Hand Measurement Value': 'Hand Measurement Value',
		'Hand Measurement Date': 'Hand Measurement Date',
		'Sensor ID1': 'Sensor ID1',
		'Sensor Type': 'Sensor Type',
		'AES Key': 'AES Key',
		'Is active sensor': 'Is active sensor',
		'Telegram exists': 'Telegram exists',
		'Telegram decrypted': 'Telegram decrypted',
		'Show telegrams': 'Show telegrams',
		'Telegrams for': 'Telegrams for',
		'Captured at': 'Collected at',
		'Captured by': 'Collected by',
		'Gateway ID': 'Gateway ID',
		Manufacturer: 'Manufacturer',
		'Device type': 'Device type',
		'Select building': 'Select building',
		'Select operator': 'Select operator',
		'Select reading date': 'Select reading date',
		'Select set date': 'Select set date',
		'Reading date': 'Reading date',
		'Set date': 'Set date',
		Building: 'Building',
		'Automatic resolve': 'Automatic resolve',
		'No conflicts which can be resolved automatically':
			'No conflicts which can be resolved automatically',
		'Resolved conflicts for {{number}} sensors':
			'Resolved conflicts for {{number}} sensors',
		'Building ID1': 'Building ID1',
		'Sensor %': 'Sensor %',
		'Serial %': 'Serial %',
		'Only with requirement / User permission': 'Only with requirement / User permission',
		RSSI: 'RSSI',
		Location: 'Location',
		'User number': 'User number',
		'Telegrams Count': 'Telegrams Count',
		'Telegram %': 'Telegram Report %',
		'Telegrams Change (%)': 'Telegrams Change (%)',
		'Total Telegram %': 'Total Telegram %',
		'{{telegramCollectedCount}} of {{serialsCount}}':
			'{{telegramCollectedCount}} of {{serialsCount}}',
		'Last exports': 'Last exports',
		'never exported': 'never exported',
	},
	building: {
		ID4: 'ID4',
		Street: 'Street',
		Number: 'Number',
		City: 'City',
		Zipcode: 'Zipcode',
		'Alternate Address': 'Alternate Address',
		'Sensors Count': 'Sensors Count',
		'Meeters Count': 'Meeters Count',
		'Serials Count': 'Serials Count',
		Refresh: 'Refresh',
		Delete: 'Delete',
		Pricing: 'Pricing',
		ID: 'ID',
		Year: 'Year',
		'Heat Variable': 'Heat Variable',
		'Heat Fixed': 'Heat Fixed',
		'Water Variable': 'Water Variable',
		'Water Fixed': 'Water Fixed',
		Sensors: 'Sensors',
		ID1: 'ID1',
		'External Number': 'External Number',
		'Set Date': 'Set Date',
		'Update Date': 'Update Date',
		Status: 'Status',
		Name: 'Name',
		Name2: 'Name 2',
		Factor: 'Factor',
		Type: 'Type',
		'Serial Number': 'Serial Number',
		Comment: 'Comment',
		'Installed Date': 'Installed Date',
		'Uninstalled Date': 'Uninstalled Date',
		Apartments: 'Apartments',
		Location: 'Location',
		'Area Heating': 'Area Heating',
		'Area Water': 'Area Water',
		'Could not upload the file': 'Could not upload the file',
		'File uploaded successfully': 'File uploaded successfully',
		'Upload Routeml': 'Upload Routeml',
		'Import method': 'Import method',
		'There are duplicate sensors in this file':
			'There are duplicate sensors in this file',
		'Apartment guid': 'Apartment guid',
		'Duplicate sensors guid': 'Duplicate sensors guid',
		'Marked Wrong': 'Recycle bin',
		'Main buildings': 'Buildings',
		'The building is going to be deleted':
			'The building is going to be deleted',
		Proceed: 'Proceed',
		Cancel: 'Cancel',
	},
	sensorKey: {
		ID: 'ID',
		'Serial Number': 'Serial Number',
		'AES Key': 'AES Key',
		'Upload CSV': 'Upload CSV',
		'Upload CSV Help': 'Upload CSV Help',
		'upload-instructions':
			'The csv file must have the header as follow: serial_number;aes_key. Note that it should be semicolon separated values',
		Understood: 'Understood',
		Manufacturer: 'Manufacturer',
		'Individual keys': 'Individual keys',
		'Global keys': 'Global keys',
	},
	gateway: {
		ID: 'ID',
		ICCID: 'ICCID',
		IMEI: 'IMEI',
		'Serial Number': 'Serial Number',
		Manufacturer: 'Manufacturer',
		'Device type': 'Device type',
		Model: 'Model',
		'Last Reading': 'Last Reading',
		Online: 'Online',
		'Building Address': 'Building Address',
		Comment: 'Comment',
		'Choose a building': 'Choose a building',
		'ICCID is required': 'ICCID is required',
		'IMEI is required': 'IMEI is required',
		'Serial number is required': 'Serial number is required',
		'Manufacturer is required': 'Manufacturer is required',
		'Model is required': 'Model is required',
		'Select building': 'Select building',
		Building: 'Building',
		Save: 'Save',
		'Gateway not found': 'Gateway not found',
		'IMEI must have 15 numbers': 'IMEI must have 15 numbers',
		'ICCID must have 22 numbers': 'ICCID must have 20-22 numbers',
		'Could not save the gateway': 'Could not save the gateway',
		Organization: 'Organization',
		'Choose an organization': 'Choose an organization',
		'Organization is required': 'Organization is required',
		'Select manufacturer': 'Select manufacturer',
		'telegrams for gateway': 'telegrams for gateway',
		'Sensor serial number': 'Sensor serial number',
		'Captured at': 'Captured at',
		'Telegram decrypted': 'Telegram decrypted',
		'Show telegrams': 'Show telegrams',
		'Telegrams for gateway': 'Telegrams for gateway',
		'Year and month': 'Year and month',
		Battery: 'Battery',
		RSSI: 'RSSI',
		Operator: 'Operator',
		Status: 'Status',
		'Import Method': 'Import Method',
		'Start automatic building assignment': 'Start automatic building assignment',
		'Automatically assign building': 'Automatically assign building',
		'Sync gateways': 'Sync gateways',
		'Building ID1': 'Building ID1',
		'Last Sending': 'Last Sending',
		'Upload CSV': 'Upload CSV',
		'Upload CSV Help': 'Upload CSV Help',
		'upload-instructions':
			'The csv file must have the header as follow: serial_number;imei;manufacturer;customer_name;iccid. Note that it should be semicolon separated values',
		Understood: 'Understood',
		'Gateways in Operations': 'Gateways in Operations',
		'Gateways Backlog': 'Gateways Backlog',
		'Show whitelist settings': 'Show whitelist settings',
		'uploaded to the ftp server until gateway will download and apply it': 'uploaded to the ftp server until gateway will download and apply it',
		'empty whitelist uploaded to the ftp server and waiting until gateway will download it to reset currect whitelist': 'empty whitelist uploaded to the ftp server and waiting until gateway will download it to reset current whitelist',
		'applied to the gateway': 'applied to the gateway',
		'not created': 'not created',
		'Gateway whitelist': 'Gateway whitelist',
		'Whitelist status': 'Whitelist status',
		'Enable whitelist': 'Enable whitelist',
		'Remove whitelist': 'Remove whitelist',
		'Gateway updated successfully': 'Gateway updated successfully',
    'Gateway updated successfully': 'Gateway updated successfully',
	},
	ftpConfig: {
		ID: 'ID',
		User: 'User',
		Password: 'Password',
		Host: 'Host',
		Port: 'Port',
		Setup: 'Setup',
		Folder: 'Folder',
	},
	integrationConfig: {
		ID: 'ID',
		User: 'User',
		Password: 'Passwort',
		'Api Key': 'Api Key',
		Host: 'Host',
		Port: 'Port',
		Type: 'Type',
	},
	operator: {
		ID: 'ID',
		'E-mail': 'E-mail',
		Password: 'Password',
		'First Name': 'First Name',
		'Last Name': 'Last Name',
		Phone: 'Phone',
	},
	user: {
		ID: 'ID',
		'E-mail': 'E-mail',
		Password: 'Password',
		'New password': 'New password',
		Role: 'Role',
		'First Name': 'First Name',
		'Last Name': 'Last Name',
		Phone: 'Phone',
		Apartment: 'Apartment',
		Building: 'Building',
		'Choose an apartment': 'Choose an apartment',
		'Choose a building': 'Choose a building',
		'Choose a role': 'Choose a role',
		Save: 'Save',
		Details: 'Details',
		'Could not save user': 'Could not save user',
		'User info saved': 'User info saved',
		'Could not add the apartment': 'Could not add the apartment',
		'Apartment added to user': 'Apartment added to user',
		'Start date': 'Start date',
		'End date': 'End date',
		'Street 1': 'Street 1',
		'Street 2': 'Street 2',
		'ZIP code': 'ZIP code',
		City: 'City',
		roles: {
			1: 'Customer',
			2: 'Operator',
			3: 'Admin Master',
			4: 'Admin User',
			10: 'Property Manager',
		},
	},
	sensor: {
		types: {
			RM: 'Smoke Detector',
			WWZ: 'Hot Water Meter',
			KWZ: 'Cold Water Meter',
			WMZ: 'Heating Meter',
			HKV: 'Heating Allocator',
			HVE: 'Electronic Heating Allocator',
			HVV: 'Evaporator Heating Allocator',
		},
		statuses: {
			0: 'Offline',
			1: 'Online',
			10: 'Disabled',
			20: 'Unused',
		},
	},
	settings: {
		Language: 'Language',
	},
	organization: {
		ID: 'ID',
		ID1: 'ID1',
		Building: 'Building',
		Buildings: 'Buildings',
		Users: 'Users',
		Name: 'Name',
		Street: 'Street',
		Number: 'Number',
		City: 'City',
		'ZIP code': 'ZIP code',
		Phone: 'Phone',
		'E-mail': 'E-mail',
		'Update Date': 'Update Date',
	},
	telegram: {
		'Telegram is not decrypted': 'Telegram is not decrypted',
		Header: 'Header',
		'Manufacturer-Specific Header': 'Manufacturer-Specific Header',
		Key: 'Key',
		Value: 'Value',
		Values: 'Values',
		Unit: 'Unit',
		Type: 'Type',
	},
	uvis: {
		USE_UVIS_INFO:
			'Please click on the property to enter owners and residents for sending the UVI.',
		USE_UVIS_INFO_ALERT:
			'ATTENTION: For the protection of personal data, you must always keep the recipients of the UVI up to date.',
		USE_UVI_BUILDING_INFO:
			'Please enter at least one UVI recipient per rental unit for monthly mailing.',
		USE_UVI_BUILDING_INFO_IMPORTANT:
			'Important: For data protection reasons, you must always keep the recipients of the UVI up to date.',
		'Missing UVI receivers': 'Missing UVI receivers',
		'Number of UVI recipients': 'Number of UVI recipients',
		USE_UVI_TENANT_INFO:
			'Please enter at least one UVI recipient per unit for monthly mailing by email or letter. The email is sent automatically. You can download the UVI with the recorded address for mailing.',
		USE_UVI_TENANT_INFO_ALERT:
			'Important: For data protection reasons, you must always keep the recipients of the UVI up to date.',
		'UVI Coverage': 'Coverage',
		'Send reports': 'Send reports',
		Scheduled: 'Scheduled',
		Error: 'Error',
		Statistic: 'Statistic',
	},
	rssi: {
		'No signal': 'No signal',
		'Very weak': 'Very weak',
		Weak: 'Weak',
		Average: 'Average',
		Strong: 'Strong',
		Excellent: 'Excellent',
		'Wrong value': 'Wrong value',
	},
	dashboard: {
		'Sensor Conflicts': 'Sensor Conflicts',
		'Sensor Fails': 'Sensor Fails',
		'AMR Coverage': 'AMR Coverage',
		'Telegram Search': 'Telegram Search',
		'Sensor serial number': 'Sensor serial number',
		'Invalid Serials': 'Invalid Serials',
		'Missing AES Keys': 'Missing AES Keys',
		'Device Counters': 'Device Counters',
		'AMR Report count': 'AMR Report count',
		'AMR Report Min coverage': 'AMR Report Min coverage',
		'AMR Report Max coverage': 'AMR Report Max coverage',
		'Status': 'Status',
		'Gateways (growth rate in last 6 months)': 'Gateways (growth rate in last 6 months)',
		'Wireless Devices (growth rate in last 6 months)': 'Wireless Devices (growth rate in last 6 months)',
		'Building (Growth Rate in last 6 months)' : 'Building (Growth Rate in last 6 months)',
		'WE (Growth Rate in last 6 months)': 'WE (Growth Rate in last 6 months)',
		'Device Issues': 'Device Issues',
		'Devices with Error Code': 'Devices with Error Code',
		'Number of Devices': 'Number of Devices',
		'Devices not seen beyond treshold': 'Devices not seen beyond treshold',
		'Devices missing AES Key': 'Devices missing AES Key',
		'Coverage Issues': 'Coverage Issues',
		'Number of Buildings': 'Number of Buildings',
		'Import Issues': 'Import Issues',
		'Double Serial Numbers': 'Double Serial Numbers',
		'Double Serial Numbers Errors': 'Double Serial Numbers Errors',
		'Buildings with Coverage below 90%': 'Buildings with Coverage below 90%',
	},
	passwordRecovery: {
		'Back to login': 'Back to login',
		'Password recovery': 'Password recovery', 
		CHANGE_PASSWORD_FORM_INFO: 'Please enter new password, it should have at least {length} characters.',
		'New password': 'New password',
		'Repeat new password': 'Repeat new password',
		'Change password': 'Change password',
		"Passwords doesn't match": "Passwords don't match",
		'Password successfully changed': 'Password successfully changed',
		PASSWORD_CHANGE_ERROR: 'An error occured during password changing',
		EMAIL_FORM_INFO: 'Please enter your email and press the button below, after that recovery code will be send to your email.',
		'Send recovery code': 'Send recovery code',
		RECOVERY_CODE_FORM_INFO: 'Please enter the code that was sent to',
		'This code is no longer valid': 'This code is no longer valid',
		'Recovery code': 'Recovery code',
		"Recovery code can't be blank": "Recovery code can't be blank",
		'Submit code': 'Submit code',
	},
	"billing": {
		"Include in billing report": "Include in billing report",
		"Exclude from billing report": "Exclude from billing report",
		"Month": "Month",
		"Total": "Total",
		"Date": "Date",
		"Download CSV": "Download CSV",
		"Set date": "Set date",
		"Error": "Error",
		"Invoices": "Invoices",
		"Rate Values": "Rate Values",
		"Rate": "Rate",
		"Description": "Description",
		"Price per item": "Price per item",
		"Default price per item": "Default price per item",
		"Fixed Price": "Fixed Price",
		"Active Gates": "Active Gates",
		"Active Sensors Radio Measured": "Active Sensors Radio Measured",
		"Active Sensors Manual Measured": "Active Sensors Manual Measured",
		"Active SIM Cards": "Active SIM Cards",
	}
};
