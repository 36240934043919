import React, {useState} from 'react';
import fp from 'lodash/fp';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';
import MaterialTable from 'material-table';
import Link from '../../../components/Link';

import ReportError from '../../../model/report-error';

const GET_SENSORS = gql`
  query getSensors($ids: [ID!]) {
    sensors(ids: $ids) {
      id
      name
      external_id
      created_at
      serial_number
      external_number
    }
  }
`;
const ErrorDetails = ({error}) => {
  const {swappedSensors} = error.details;
  const swappedSensorIds = swappedSensors.map((sensor) => sensor.id);
  const {
    loading,
    error: isError,
    data,
  } = useQuery(GET_SENSORS, {
    variables: {
      ids: swappedSensorIds,
    },
  });
  const {t} = useTranslation();

  if (isError) {
    return <div>{t('errors:Failed to load the data')}</div>;
  }
  return (
    <MaterialTable
      options={{search: false, paging: false, sorting: false}}
      title={t('reportErrors:Swapped sensors')}
      isLoading={loading}
      data={loading ? [] : data.sensors}
      columns={[
        {
          title: t('building:ID'),
          field: 'id',
        },
        {
          title: t('building:ID1'),
          field: 'external_id',
        },
        {
          title: t('building:Name'),
          field: 'name',
        },
        {
          title: t('building:Serial Number'),
          field: 'serial_number',
        },
        {
          title: t('building:ID4'),
          field: 'external_number',
        },

      ]}
    />
  );
};
ErrorDetails.propTypes = {
  error: ReportError.propType,
};

const ErrorSensorSwappedSerial = ({error}) => {
  const {t} = useTranslation('reportErrors');
  const [extended, setExtended] = useState(false);
  const {swappedSensors} = error.details;
  const swappedSerialNumber = swappedSensors.map((sensor) => sensor.serialNumber);

  const toggle = (e) => {
    e.preventDefault();
    setExtended((oldState) => !oldState);
  };

  const alertText = t('External number and serial number are mixed up');

  return (
    <>
      <Alert title={error.message} severity="error">
        {alertText} (
        <Link href="#" onClick={toggle} underline="always">
          {(swappedSerialNumber || []).join(', ')}
        </Link>
        )
      </Alert>
      {extended && <ErrorDetails error={error} />}
    </>
  );
};

ErrorSensorSwappedSerial.propTypes = {
  error: ReportError.propType,
};

export default ErrorSensorSwappedSerial;
